const { tableColumnConfig} = require('../config/payManager/cash.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
			expandComponentName:"CashDetail",
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/cashBack/page"
		this.listDelConfig.url= "/sys/cashBack/remove"
		this.listDelConfig.methods = "POST"
		this.listDelConfig.type = "arr"
		this.listDelConfig.valFiled = "cashBackId"
		
		this.queryOptions.queryType="conditionQuery"
		this.queryOptions.keys = "orderNumber"
		this.queryOptions.placeHolder ="请输入订单号"
		this.queryOptions.conditionOption = [//1:待付款 2:待发货 3:待收货 4:待评价 5:成功 6:失败
			{label:"审核状态",type:"dropDownList",prop:"status",tips:"请选择",span:8,md:6,option:[{label:"未处理 ",value:0},{label:"通过",value:1},{label:"拒绝",value:-1}]},
		]
	},
	methods: {
		examine(type,data){
			this.$confirm('是否通过审核？', '操作提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '通过',
				cancelButtonText: '拒绝'
			})
			.then(async () => {
				const res = await this.$axios('POST',`/sys/cashBack/examine`,{
					cashBackId:data.cashBackId,
					status:1
				}, true)
				if (res.code == '30001') {
					return this.$message.warning(res.msg);
					// return this.$notify.warning(res.msg);
				}
				this.$message.success('已审核通过！');
				this.getListData()
			})
			.catch( async action => {
				if(action=='cancel'){
					const res = await this.$axios('POST',`/sys/cashBack/examine`,{
						cashBackId:data.cashBackId,
						status:-1
					}, true)
					if(res.code=='30001'){
						return this.$message.warning(res.msg);
					}
					this.$message.success('已拒绝！');
					this.getListData()
				}
			});
		}
	},
	components: {
		"CashDetail": () => import("@/components/businessComponent/cashDetail.vue"),
	},
}