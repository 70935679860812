const { tableColumnConfig} = require('../config/system/handleLog.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/sysLogInfo/page"
		this.rightHandleAreaConfig.minwidth="60"
		this.tableConfig.tableHeight = "600"
	},
}