module.exports = {
	data(){
		return {
			tableData: [{
				date: '阿萨德',
				name: '萨达',
				header:"http://localhost:8080/img/q.342e6b1d.png",
				address: '阿萨德 1518 弄',
				city:"啊实打实"
			  }, {
				date: '阿达',
				header:"/img/img.146655c9.jpg",
				name: '啊实打实',
				address: '上海市普陀区金沙江路 1517 弄',
				city:"背景"
			  }, {
				date: '2016-05-01',
				header:"/img/img.146655c9.jpg",
				name: '王小虎',
				address: '上海市普陀区金沙江路 1519 弄',
				city:"背景"
			  }, {
				date: '2016-05-03',
				header:"/img/img.146655c9.jpg",
				name: '王小虎',
				address: '上海市普陀区金沙江路 1516 弄',
				city:"背景"
			  }]
		}
	},
	components: {
		
	},
	mounted(){
		
	},
	methods:{
		
	}
}
