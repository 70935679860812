module.exports = {
	tableColumnConfig: [{
			prop: "couponTemplateName",
			label: "模板名称",
		},
		{
			prop: "couponTemplateType",
			label: "优惠类型",
			type: "state",
			width: "100",
			option: [{
				label: "满减券",
				value: 1
			}, {
				label: "立减券",
				value: 2
			}, {
				label: "折扣券",
				value: 3
			}]
		},
		{
			prop: "couponFixedAmount",
			label: "立减金额",
			width: "100",
		},
		{
			prop: "couponDiscount",
			label: "折扣",
			width: "100",
		},
		{
			prop: "couponMaxQuota",
			label: "折扣最大额度",
			width: "110",
		}, 
		// {
		// 	prop: "couponQuota",
		// 	label: "满减条件",
		// }, 
		{
			prop: "couponStatus",
			label: "状态",
			type: "state",
			width: "100",
			option: [{
				label: "禁用",
				value: 1
			}, {
				label: "正常",
				value: 0
			}]
		},
		{
			prop: "couponTemplateExpireType",
			label: "到期类型",
			type: "state",
			width: "100",
			option: [{
				label: "固定时间",
				value: 1
			}, {
				label: "永久",
				value: 0
			}, {
				label: "相对时效",
				value: 2
			}]
		},
		{
			prop: "couponTemplateExpireStartTime",
			label: "开始时间",
		}, {
			prop: "couponTemplateExpireEndTime",
			label: "结束时间",
		},
		{
			prop: "couponTemplateScopeType",
			label: "优惠使用范围",
			type: "state",
			width: "110",
			option: [{
				label: "全部",
				value: 0
			}, {
				label: "部分商品",
				value: 1
			}]
		}, 
		{
			prop: "couponTemplateScopeName",
			label: "关联商品",
		}, 
		// {
		// 	prop: "createTime",
		// 	label: "创建时间",
		// },
	],
	formLabelConfig: [{
			prop: "couponTemplateName",
			label: "模板名称",
		},
		{
			prop: "couponTemplateType",
			label: "优惠类型",
			showType: "select",
			// option:[{label:"满减券",value:1},{label:"立减券",value:2},{label:"折扣券",value:3}]
			option: [{
				label: "立减券",
				value: 2
			}, {
				label: "折扣券",
				value: 3
			}]
		},
		{
			prop: "couponDiscount",
			label: "折扣",
		},
		{
			prop: "couponMaxQuota",
			label: "折扣最大额度"
		},
		{
			prop: "couponTemplateExpireStartTime",
			label: "开始时间",
			showType: "date",
			allowNull: true
		},
		{
			prop: "couponTemplateExpireEndTime",
			label: "结束时间",
			showType: "date",
			allowNull: true
		},
		{
			prop: "couponTemplateExpireType",
			label: "到期类型",
			showType: "select",
			option: [{
				label: "固定时间",
				value: 1
			}, {
				label: "永久",
				value: 0
			}, {
				label: "相对时效",
				value: 2
			}],
		},
		{
			prop: "couponTemplateScopeType",
			label: "优惠使用范围",
			showType: "select",
			option: [{
				label: "全部",
				value: 0
			}, {
				label: "部分商品",
				value: 1
			}]
		},
		{
			prop: "couponStatus",
			label: "状态",
			showType: "select",
			option: [{
				label: "禁用",
				value: 1
			}, {
				label: "正常",
				value: 0
			}]
		}
	],
	formLabelConfig2: [{
			prop: "couponTemplateName",
			label: "模板名称",
		},
		{
			prop: "couponTemplateType",
			label: "优惠类型",
			showType: "select",
			// option:[{label:"满减券",value:1},{label:"立减券",value:2},{label:"折扣券",value:3}]
			option: [{
				label: "立减券",
				value: 2
			}, {
				label: "折扣券",
				value: 3
			}]
		},
		{
			prop: "couponFixedAmount",
			label: "立减金额",
			showType: "number",
			testType: "number", //验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption: {
				min: 0, //最小
			} //验
		},
		{
			prop: "couponTemplateExpireStartTime",
			label: "开始时间",
			showType: "date",
			allowNull: true
		},
		{
			prop: "couponTemplateExpireEndTime",
			label: "结束时间",
			showType: "date",
			allowNull: true
		},
		{
			prop: "couponTemplateExpireType",
			label: "到期类型",
			showType: "select",
			option: [{
				label: "固定时间",
				value: 1
			}, {
				label: "永久",
				value: 0
			}],
		},
		{
			prop: "couponTemplateScopeType",
			label: "优惠使用范围",
			showType: "select",
			option: [{
				label: "全部",
				value: 0
			}, {
				label: "部分商品",
				value: 1
			}]
		},
		{
			prop: "couponStatus",
			label: "状态",
			showType: "select",
			option: [{
				label: "禁用",
				value: 1
			}, {
				label: "正常",
				value: 0
			}]
		}
	],
	formLabelConfig3: [{
			prop: "couponTemplateName",
			label: "模板名称",
		},
		{
			prop: "couponTemplateType",
			label: "优惠类型",
			showType: "select",
			// option:[{label:"满减券",value:1},{label:"立减券",value:2},{label:"折扣券",value:3}]
			option: [{
				label: "立减券",
				value: 2
			}, {
				label: "折扣券",
				value: 3
			}]
		},
		{
			prop: "couponFixedAmount",
			label: "立减金额",
			showType: "number",
			testType: "number", //验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption: {
				min: 0, //最小
			} //验
		},
		// {
		// 	prop: "couponTemplateExpireStartTime",
		// 	label: "开始时间",
		// 	showType: "date",
		// 	allowNull: true
		// },
		// {
		// 	prop: "couponTemplateExpireEndTime",
		// 	label: "结束时间",
		// 	showType: "date",
		// 	allowNull: true
		// },
		{
			prop: "couponTemplateExpireType",
			label: "到期类型",
			showType: "select",
			option: [{
				label: "固定时间",
				value: 1
			}, {
				label: "永久",
				value: 0
			}],
		},
		{
			prop: "couponTemplateScopeType",
			label: "优惠使用范围",
			showType: "select",
			option: [{
				label: "全部",
				value: 0
			}, {
				label: "部分商品",
				value: 1
			}]
		},
		{
			prop: "couponStatus",
			label: "状态",
			showType: "select",
			option: [{
				label: "禁用",
				value: 1
			}, {
				label: "正常",
				value: 0
			}]
		}
	],
	formDataProp: {


	}
}
