module.exports = {
	tableColumnConfig: [
		{
			prop: "orderNumber",
			label: "订单号",
		},
		{
			prop: "payAmount",
			label: "支付金额",
		},
		{
			prop: "payNo",
			label: "支付单号",
		},
		{
			prop: "payStatus",
			label: "支付状态",
			type: "state",
			option:[{label:"未支付",value:0},{label:"已支付",value:1}],
		},
		{
			prop: "payType",
			label: "支付方式",
			type: "state",
			option:[{label:"微信支付",value:1},{label:"支付宝支付",value:2},{label:"余额支付",value:3}],
		},
		{
			prop: "isClearing",
			label: "是否分账",
			type: "state",
			option:[{label:"未分账",value:0},{label:"已分账",value:1}],
		},
		{
			prop: "clearingTime",
			label: "分账时间",
		},
		{
			prop: "createTime",
			label: "创建时间",
		},
	]
}
