module.exports = {
	data(){
		return{
			// getCfgUrl:"http://wuchian.xicp.cn:8005/Service/ListServiceBase.ashx/GetGridList"
			// getDataUrl:"/Service/ListServiceBase.ashx/GetGridData",
			// deleteDataUrl:"/Service/ListServiceBase.ashx/DeleteDataByIds"
			queryType:"fuzzyQuery"
		}
	},
	created(){
		this.parameters={CategoryId:this.$route.query.id}
		this.queryOptions={ queryName: "@FixSearch", fields: "Title,ArticleContent",placeHolder:"标题，内容"}
		// this.showToolBar = false
		// this.defauleWidth = "30%"//定义弹窗宽度
	},
	mounted(){
		console.log(this.tableData)
	},
	methods: {
		// NewData() {
		// 	alert(15)
		// }
		// searchList(val){
		// 	console.log(val)
		// 	this.parameters={
		// 		CategoryId:this.$route.query.id,
		// 		"Condition":val
		// 	}
		// 	this.getData();
		// 	this.getDataTotal();
		// },
		//数据列表中自定义某项弹窗的用法，方法在配置工具配置
		checkView(row){
			console.log(row)
			this.customDialogVisible = !this.customDialogVisible
			this.titleName = "自定义"
			this.customComponentName = "check"
		}
	},
	components: {
		// "check": () => import("@/components/customComp/check.vue")
	},
	// customtpl:"<div>12</div>",//自定义模板
	// getCfgUrl:"",//获取配置的url
	// customFormDialog:true//自定义表单
}

// 
// <template slot-scope="scope">
// <router-link :to="{ path: '/articles/Articles', query: {id:scope.row.CategoryId,custom:1}}">编辑条目</router-link>
// </template>