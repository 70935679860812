const { tableColumnConfig} = require('../config/store/ProductList.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
			expandComponentName:"productSkuList",
		}
	},
	created(){
		// 产品分类
		// 年代茶（70 80 90） 大师茶   北大1898（燕园情）
		//列表数据请求接口参数配置
		this.formDialogConfig.defauleWidth="60%"
		this.listAxiosConfig.url="/sys/commodity/list"
		this.listDelConfig.url= "/sys/commodity/delete"
		this.listDelConfig.keyFiled = "prodId"
		this.listDelConfig.valFiled = "commodityId"     
		this.$vue.$on("fchange",_=>{
			this.refreshList();
		})
	},
	customFormDialog:true,//自定义表单
	components: {
		"productSkuList": () => import("@/components/customList/productSkuList.vue"),
	},
}