module.exports = {
	tableColumnConfig: [{
			prop: "name",
			label: "角色名",
		},
		{
			prop: "code",
			label: "角色代码",
		},
		{
			prop: "status",
			label: "状态",
			type:"state",
			option:[
				{label:"禁用",value:0},
				{label:"启用",value:1}
			]
		},
		{
			prop: "createTime",
			label: "创建时间"
		}
	],
	formLabelConfig: [{
			prop: "name",
			label: "角色名"
		},
		{
			prop: "code",
			label: "角色代码",
			// readOnly:true
		},
		{
			prop: "status",
			label: "状态",
			showType:"select",
			option:[{label:"禁用",value:0},{label:"启用",value:1}]
		}
	],
	formDataProp:{
		name:"",
		code:"",
		status:1,
	}
}
