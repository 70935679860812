module.exports = {
	tableColumnConfig: [{
			prop: "integralSettingId",
			label: "积分设置id",
			type: "text", //配置内容显示格式
		},
		{
			prop: "multiple",
			label: "倍率",
			type: "text",
		},
		{
			prop: "createTime",
			label: "创建时间",
			type: "text",
		},
		{
			prop: "status",
			label: "状态",
			type: "state",
			option:[{label:"正常",value:0},{label:"禁用",value:1}]
		}
	],
	formLabelConfig: [
		{
			prop: "multiple",
			label: "倍率",
			type: "text",
		},
		{
			prop: "status",
			label: "状态",
			showType:"select",
			option:[{label:"正常",value:0},{label:"禁用",value:1}]
		}
	],
	formDataProp:{
		status:0
	}
}
