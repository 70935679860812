module.exports = {
	tableColumnConfig: [{
			prop: "commodityName",
			label: "商品名称",
			width:"260"
		},
		{
			prop: "commodityDescribe",
			label: "商品描述",
			width:"260"
		},
		{
			prop: "commodityPic",
			label: "商品分类主图",
			type: "img",
			align: "center",
			width:"120"
		},
		{
			prop: "commodityImages",
			label: "商品详情轮播图",
			type: "mimg",//多图
			align: "center",
			width:"240"
		},
		// {
		// 	prop: "commodityActualPirce",
		// 	label: "实际价格",
		// 	width:"100"
		// },
		// {
		// 	prop: "commodityOriginalPrice",
		// 	label: "原价",
		// 	width:"100"
		// },
		// {
		// 	prop: "commodityStock",
		// 	label: "库存",
		// 	align: "center",
		// 	width:"100"
		// },
		{
			prop: "skuList",
			label: "商品规格",
			type:"expand",
			width:"100"
		},
		{
			prop: "commodityStatus",
			label: "商品状态",
			align: "center",
			type:"state",
			width:"100",
			option:[{label:"下架",value:0},{label:"上架",value:1}]
		}
	],
	formLabelConfig: [{
			prop: "commodityName",
			label: "商品名称",
		},
		{
			prop: "categoryId",
			label: "商品类目",
			showType:"select",
			option:[]
		},
		{
			prop: "deliveryTemplateId",
			label: "运费模板",
			showType:"select",
			option:[]
		},
		{
			prop: "labelId",
			label: "标签",
			showType:"mSelect",
			option:[]
		},
		// {
		// 	prop: "commodityActualPirce",
		// 	label: "实际价格",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		// {
		// 	prop: "commodityOriginalPrice",
		// 	label: "原价",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		// {
		// 	prop: "skuCostPrice",
		// 	label: "成本价",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		// {
		// 	prop: "skuVipPrice",
		// 	label: "会员价",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		// {
		// 	prop: "commodityStock",
		// 	label: "库存",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:1,//最小
		// 	}//验
		// },
		// {
		// 	prop: "skuVolume",
		// 	label: "商品体积(cm²)",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		// {
		// 	prop: "skuWeight",
		// 	label: "商品重量(kg)",
		// 	showType:"number",
		// 	testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
		// 	testTypeOption:{
		// 		min:0,//最小
		// 	}//验
		// },
		{
			prop: "commodityStatus",
			label: "商品状态",
			showType:"select",
			option:[{label:"上线",value:1},{label:"下线",value:0}]
		},
		{
			prop: "commodityDescribe",
			label: "商品描述",
			showType:"textarea"
		},
		{
			prop: "commodityPic",
			label: "商品分类主图",
			showType:"imgUploader",
			limitNum:1
		},
		{
			prop: "commodityImages",
			label: "商品详情轮播图",
			showType:"mImgUploader",//多图
			limitNum:3
		},
		{
			prop: "commodityIntroduce",
			label: "商品详情",
			showType:"editor",
		},
		{
			prop: "commodityIntroduceTwo",
			label: "产品故事",
			showType:"editor",
		}
	],
	formDataProp:{
		// categoryId:"",//分类id
		// commodityName:"",
		// commodityDescribe:"",//描述
		// commodityActualPirce:0,
		// commodityOriginalPrice:0,
		// commodityStatus:1,
		// deliveryTemplateId:1,
		// labelId:[],
		// commodityPic:"",
		// skuList:[],
	}
}
