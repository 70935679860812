module.exports = {
	tableColumnConfig: [{
			prop: "propName",
			label: "属性名称",
			width:300,
		},
		{
			prop: "prodPropValues",
			label: "属性值",
			type:"tags",
			key:"propValue",
			style:"info",
			width:700,
		},
	],
	formLabelConfig: [{
			prop: "propName",
			label: "属性名称",
		}
	],
	formDataProp:{
		// labelName:"",
		// labelCode:"",
		// sort:0,
		// status:0,
	}
}
