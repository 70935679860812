module.exports = {
	tableColumnConfig: [{
			prop: "labelName",
			label: "标签名称",
			width:220,
		},
		{
			prop: "labelCode",
			label: "标签code",
			width:220,
		},
		{
			prop: "sort",
			label: "排序",
			width:220,
			align: "center",
		},
		{
			prop: "status",
			label: "是否禁用",
			align: "center",
			width:220,
			type:"state",
			option:[{label:"启用",value:0},{label:"禁用",value:1}]
		},
		{
			prop: "createTime",
			label: "创建时间",
			width:220,
		},
		{
			prop: "updateTime",
			label: "更新时间",
			width:220,
		}
	],
	formLabelConfig: [{
			prop: "labelName",
			label: "标签名称",
		},
		{
			prop: "labelCode",
			label: "标签code",
		},
		{
			prop: "sort",
			label: "排序",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop: "status",
			label: "是否禁用",
			showType:"select",
			option:[{label:"启用",value:0},{label:"禁用",value:1}]
		}
	],
	formDataProp:{
		labelName:"",
		labelCode:"",
		sort:0,
		status:0,
	}
}
