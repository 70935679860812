const {tableColumnConfig} = require('../config/payManager/Withdrawal.js');
module.exports = {
	data() {
		return {
			tableColumn: tableColumnConfig, //表格表头配置
		}
	},
	created() {
		//列表数据请求接口参数配置
		this.listAxiosConfig.url = "/sys/withdrawal/Page"
	},
	mounted() {},
	methods: {
		//自定义弹窗
		// viewData(type,item){
		// 	this.currentSelectRowData = item
		// 	this.formDialogConfig.customDialogVisible = true
		// 	this.formDialogConfig.title = "提现申请详情"
		// 	this.customComponentName = "WithdrawalDetail"
		// },
		agreeRequest(item, prop) {
			let status = 1
			this.$confirm('是否通过审核？', '操作提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '通过',
				cancelButtonText: '拒绝'
			})
			.then(async () => {
				const res = await this.$axios('POST',`/sys/withdrawal/examine`,{
					withdrawalId:prop.withdrawalId,
					userId:prop.userId,
					status:2
				}, true)
				if (res.code == '30001') {
					return this.$message.warning(res.msg);
					// return this.$notify.warning(res.msg);
				}
				this.$message.success('已审核通过！');
				this.refreshList()
			})
			.catch( async action => {
				if(action=='cancel'){
					status = -1
					const res = await this.$axios('POST',`/sys/withdrawal/examine`,{
						withdrawalId:prop.withdrawalId,
						userId:prop.userId,
						status:3
					}, true)
					if(res.code=='30001'){
						return this.$message.warning(res.msg);
					}
					this.$message.success('已拒绝！');
					this.refreshList()
				}
			});
		}
	},
	components: {
		"WithdrawalDetail": () => import("@/components/businessComponent/withdrawalDetail.vue")
	},
}
