module.exports = {
	tableColumnConfig: [{
			prop: "levelName",
			label: "等级名称",
		},
		// {
		// 	prop: "levelId",
		// 	label: "等级id",
		// 	type: "text",
		// },
		{
			prop: "code",
			label: "是否合伙人",
			type:"state",
			width:"120",
			align:"center",
			option:[{label:"不是",value:"ORDINARY_USERS"},{label:"是",value:"MEMBER"}]
		},
		{
			prop: "status",
			label: "状态",
			type: "state",
			width:"110",
			align:"center",
			option:[{label:"正常",value:0},{label:"禁用",value:1}]
		},
		{
			prop: "associatedCouponName",
			label: "关联优惠",
			type:"tagstr",
			width:"400",
		},
	],
	formLabelConfig: [
		{
			prop:"levelName",
			label: "等级名称",
		},
		{
			prop: "code",
			label: "是否合伙人",
			showType:"select",
			option:[{label:"不是",value:"ORDINARY_USERS"},{label:"是",value:"MEMBER"}]
		},
		// {
		// 	prop: "associatedCouponId",
		// 	label: "关联优惠",
		// 	showType:"tapInput"
		// },
		{
			prop: "associatedCouponName",
			label: "关联优惠",
			allowNull:false,
			showType:"tapInput"
		},
		{
			prop:"status",
			label: "状态",
			showType:"select",
			option:[{label:"正常",value:0},{label:"禁用",value:1}]
		},
	],
	formDataProp:{
		levelName:"",
		status:0,
		associatedCouponId:1
	}
}
