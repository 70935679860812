const { tableColumnConfig} = require('../config/payManager/payInfo.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/orderPayinfo/page"
		// this.rightHandleAreaConfig.minwidth="400"
		// this.listDelConfig.url= "/sys/yunke/mgr/deletedById"
		// this.listDelConfig.methods = "POST"
	},
	methods: {
	},
}