const { tableColumnConfig} = require('../config/payManager/refundList.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/orderRefund/Page"
		
		this.rightHandleAreaConfig.minwidth="300"
		
		this.queryOptions.queryType="conditionQuery"
		this.queryOptions.keys = "orderNumber"
		this.queryOptions.placeHolder ="请输入订单流水号"
		this.queryOptions.conditionOption = [//1:待付款 2:待发货 3:待收货 4:待评价 5:成功 6:失败
			{label:"退款状态",type:"dropDownList",prop:"refundSts",tips:"请选择",span:8,md:6,option:[{label:"待审核 ",value:1},{label:"同意",value:2},{label:"不同意",value:3}]},
			{label:"处理状态",type:"dropDownList",prop:"returnMoneySts",tips:"请选择",span:8,md:6,option:[{label:"退款处理中 ",value:0},{label:"退款成功",value:1},{label:"退款失败",value:-1}]},
			{label:"申请时间",type:"date",prop:"applyTime",span:8,md:6},
		]
	},
	mounted(){
		
	},
	methods: {
		async agreeRequest(item, prop)
		{
			// 同意退款
			var refundInfo = {
				"note": "",
				"refundAmount": 0,
				"refundId": "",
				"status": 0
			};

			refundInfo.note = prop.note==undefined? "":prop.note;
			refundInfo.refundAmount = prop.refundAmount==null? 0:prop.refundAmount;
			refundInfo.refundId = prop.refundId;
			refundInfo.status = 2; // 同意退款

			refundInfo.refundAmount = 0.8

			this.$axios('POST',"/sys/orderRefund/examineRefund",refundInfo,true)
		},
		refuseRequest(item, prop)
		{
			// 拒绝退款
			var refundInfo = {
				"note": "",
				"refundAmount": 0,
				"refundId": "",
				"status": 0
			};

			refundInfo.note = prop.note;
			refundInfo.refundAmount = prop.refundAmount;
			refundInfo.refundId = prop.refundId;
			refundInfo.status = 3; // 不同意退款

			this.$axios('POST',"/sys/orderRefund/examineRefund",refundInfo,true)
		},
	},
	components: {
	},
}