const { tableColumnConfig} = require('../config/couponActivity/couponTemplate.js'); // 优惠券模版
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/couponTemplate/Page"
		//搜索配置
		this.queryOptions.queryType="conditionQuery"
		this.queryOptions.keys = "couponTemplateName"
		this.queryOptions.placeHolder ="请输入模板名称"
		this.queryOptions.conditionOption = [//1:待付款 2:待发货 3:待收货 4:待评价 5:成功 6:失败
			{label:"优惠类型",type:"dropDownList",prop:"couponTemplateType",tips:"请选择",span:8,md:6,option:[{label: "满减券",value: 1}, {label: "立减券",value: 2}, {label: "折扣券",value: 3}]},
			{label:"到期类型",type:"dropDownList",prop:"couponTemplateExpireType",tips:"请选择",span:8,md:6,option:[{
				label: "固定时间",
				value: 1
			}, {
				label: "永久",
				value: 0
			}, {
				label: "相对时效",
				value: 2
			}]},
			{label:"优惠使用范围",type:"dropDownList",prop:"couponTemplateScopeType",tips:"请选择",span:8,md:6,labelwidth:"150px",option:[{
				label: "全部",
				value: 0
			}, {
				label: "部分商品",
				value: 1
			}]},
		]
	},
	mounted(){
	},
	methods: {
		test(item,prop){//测试列表上按钮携带的点击事件
			console.log(item)
			console.log(prop)
		}
	},
	components: {
	},
}