const { tableColumnConfig} = require('../config/system/user.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.formDialogConfig.customWidth = "30%"
		this.listAxiosConfig.url="/sys/yunke/mgr/selectAllbypage"
		this.rightHandleAreaConfig.minwidth="400"
		this.listDelConfig.url= "/sys/yunke/mgr/deletedById"
		this.listDelConfig.methods = "POST"
		// this.listDelConfig.valFiled = "imgId"
	},
	mounted(){
	},
	methods: {
		resetPwd(type,item){
			this.currentSelectRowData = item
			this.formDialogConfig.customDialogVisible = true
			this.formDialogConfig.title = "重置密码"
			this.customComponentName = "ResetPwd"
		},
		//自定义弹窗
		bindRole(type,item){
			this.currentSelectRowData = item
			this.formDialogConfig.customDialogVisible = true
			this.formDialogConfig.title = "绑定角色"
			this.customComponentName = "BindRole"
		}
	},
	components: {
		"BindRole": () => import("@/components/businessComponent/bindRole.vue"),
		"ResetPwd": () => import("@/components/businessComponent/resetPwd.vue")
	},
}