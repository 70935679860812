const { tableColumnConfig} = require('../config/store/ProductSwiperImg.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/indexImg/page"
		this.listDelConfig.url= "/sys/indexImg/remove"
		this.listDelConfig.methods = "POST"
		this.listDelConfig.type = "arr"
		this.listDelConfig.valFiled = "imgId"
		this.rightHandleAreaConfig.minwidth="300"
	},
	mounted(){
	},
	methods: {
		// test(item,prop){//测试列表上按钮携带的点击事件
		// 	console.log(item)
		// 	console.log(prop)
		// },
		//自定义弹窗
		// bindRole(type,item){
		// 	this.currentSelectRowData = item
		// 	this.formDialogConfig.customDialogVisible = true
		// 	this.formDialogConfig.title = "绑定角色"
		// 	this.customComponentName = "bindRole"
		// }
	},
	// components: {
	// 	"bindRole": () => import("@/components/businessComponent/bindRole.vue")
	// },
}