module.exports = {
	tableColumnConfig: [{
			prop: "title",
			label: "标题",
			type: "text", //配置内容显示格式
		},
		{
			prop: "des",
			label: "描述",
		},
		{
			prop: "imgUrl",
			label: "图片",
			type: "img",
			align: "center"
		},
		{
			prop: "link",
			label: "链接",
		},
		{
			prop:"relationName",
			label: "关联商品",
		}
	],
	formLabelConfig: [{
			prop: "title",
			label: "标题",
		},
		{
			prop:"des",
			label: "描述",
		},
		{
			prop: "imgUrl",
			label: "图片",
			showType:"imgUploader",
			limitNum:1
		},
		{
			prop: "link",
			label: "链接",
			allowNull:false,
		},
		// {
		// 	prop: "relation",
		// 	label: "关联商品id",
		// 	showType:"tapInput"
		// },
		{
			prop: "relationName",
			label: "关联商品",
			showType:"tapInput"
		}
	],
	formDataProp:{
		// title:"",
		// des:"",
		// imgUrl:"",
		// link:"",
		// relation:"",
		// relationName:""
	}
}
