module.exports = {
	tableColumnConfig: [{
			prop: "nickName",
			label: "昵称",
			type: "text", //配置内容显示格式
		},
		{
			prop: "realName",
			label: "用户名字",
			type: "text",
		},
		{
			prop: "userMobile",
			label: "手机号码",
			type: "text",
		},
		{
			prop: "levelName",
			label: "用户等级",
			type: "text",
		},
		{
			prop: "balance",
			label: "余额",
			type: "text",
		},
		{
			prop: "status",
			label: "状态",
			type: "state",
			option:[{label:"正常",value:1},{label:"禁用",value:0}]
		}
	],
	formLabelConfig: [
		{
			prop: "realName",
			label: "用户名字",
		},
		{
			prop: "userLevelId",
			label: "用户等级",
			showType:"select",
			option:[]
		},
		{
			prop: "status",
			label: "状态",
			showType:"select",
			option:[{label:"正常",value:1},{label:"禁用",value:0}]
		}
	],
	formDataProp:{
	}
}
