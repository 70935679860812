const { tableColumnConfig} = require('../config/userManager/userLevel.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/userLevel/Page"
		
		// this.listAxiosConfig.methods="POST",
		// this.listAxiosConfig.date={
		// 	pageNum:this.paginationConfig.page,
		// 	pageSize:this.paginationConfig.pageSize
		// }
		this.rightHandleAreaConfig.minwidth="300"
	},
	mounted(){
	},
	methods: {
	},
	components: {
	},
}