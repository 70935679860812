module.exports = {
	tableColumnConfig: [{
			prop: "categoryName",
			label: "类目名称",
		},
		{
			prop: "pic",
			label: "类目图标",
			type: "img",
			align: "center",
		},
		{
			prop:"seq",
			label: "排序",
			align: "center",
		},
		{
			prop:"status",
			label: "是否上线",
			align: "center",
			type:"state",
			option:[{label:"上线",value:1},{label:"下线",value:0}]
		}
	],
	formLabelConfig: [{
			prop: "categoryName",
			label: "类目名称",
		},
		{
			prop:"seq",
			label: "排序",
			showType:"number",
			testType:"number",//验证类型number,digits,percent,mobile,phone,email,postal
			testTypeOption:{
				min:0,//最小
			}//验
		},
		{
			prop:"status",
			label: "是否上线",
			showType:"select",
			option:[{label:"上线",value:1},{label:"下线",value:0}]
		},
		{
			prop: "pic",
			label: "类目图标",
			showType:"imgUploader",
			allowNull:false,
			limitNum:1
		},
	],
	formDataProp:{
		categoryName:"",
		pic:"",
		seq:0,
		status:1,
		parentId:0,
		grade:0
	}
}
