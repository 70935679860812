const { tableColumnConfig} = require('../config/store/ProductTags.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		// 产品分类
		// 年代茶（70 80 90） 大师茶   北大1898（燕园情）
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/label/page"
		// this.listDelConfig.url= "/sys/category/delete"
		// this.listDelConfig.keyFiled = "categoryId"
	},
}