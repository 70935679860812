<template>
	<div id="box">
		<keep-alive>
			<transition name="nodebook" mode="out-in"><component v-bind:is="cname"></component></transition>
		</keep-alive>
	</div>
</template>

<script>
import { myMixin, getTemplate } from '../assets/js/elementListBase.js';
import Vue from 'vue';
import axios from 'axios';
import { mapState } from 'vuex';
var glistName = {};
var customMixin = {};
export default {
	data() {
		return {
			num: 0,
			cname: 'ElBase_0',
			publicPath: process.env.VUE_APP_BASE_API
		};
	},
	created() {
		// this.$route.params.id 获取路由自定义参数，即path: '/Categories/:id',//动态路由的id,对应菜单路径/Categories/Categories，此处统一用来作为自定义js文件、自定义组件的名字glistName.gname
		if (this.$route.params.id) {
			glistName.gname = this.$route.params.id;
		} else {
			glistName.gname = this.$route.name || '';
		}
		glistName.parameters = {};
		this.num++;
		//设置动态组件的组件名称
		this.cname = 'ElBase_' + this.num;
		//定义是否引入自定义js文件的标识,1表示引入本地自定义文件，2表示引入网络自定义文件
		let customListType;
		//通过菜单配置url路径参数来设置  /Categories/Categories?custom=1&customform=1
		if (this.$route.query.custom) {
			customListType = this.$route.query.custom;
		}
		//动态注册新组建
		Vue.component(this.cname, function(resolve, reject) {
			bas(resolve, reject, customListType);
		});
	},
	watch: {
		//监测路由变化，动态切换组件
		$route(to, from) {
			if (to.params.id) {
				glistName.gname = to.params.id;
			} else {
				glistName.gname = to.name || '';
			}
			this.cname = to.params.id || to.name;
			let customListType;
			if (to.query.custom) {
				customListType = to.query.custom;
			}
			//判断组件是否存在，不存在再注册
			if (!this.$root.$options.components[this.cname]) {
				Vue.component(this.cname, function(resolve, reject) {
					bas(resolve, reject, customListType);
				});
			}
		}
	},
	components: {
		ElBase_0: function(resolve, reject) {
			bas(resolve, reject, null);
		}
	}
};

function bas(resolve, reject, customListType) {
	//如果自定义js，则在指定位置引入对应路由id名称的自定义js文件
	if (customListType == 1) {
		customMixin = require(`@/assets/customlistjs/${glistName.gname}.js`);
		if (customMixin.getCfgUrl) {
			glistName.baseUrl = customMixin.getCfgUrl;
		}
		if (customMixin.customtpl) {
			getTpl(customMixin.customtpl);
		} else {
			getTpl();
		}
	} else if (customListType == 2) {
		//引入网络自定义文件
		axios.get(`/customlistjs/${glistName.gname}.js`).then(function(res) {
			eval(res.data);
			if (customMixin.getCfgUrl) {
				glistName.baseUrl = customMixin.getCfgUrl;
			}
			if (customMixin.customtpl) {
				getTpl(customMixin.customtpl);
			} else {
				getTpl();
			}
		});
	} else {
		customMixin = {};
		getTpl();
	}
	function getTpl(customtpl) {
		let isCustomForm = customMixin.customFormDialog; //是否自定义弹窗表单
		let tpl = getTemplate();
		if (customtpl) tpl = customtpl;
		resolve({
			mixins: [myMixin, customMixin ? customMixin : {}],
			computed: {
				...mapState({
					btnList: state => state.right.btnList,
					currentRouterTitle: state => state.config.currentRouterTitle,
					currentRouterItem: state => state.config.currentRouterItem
				})
			},
			created: function() {
				let topBtnArr = [],
					rightBtnArr = [];
				console.log(this.btnList)
				if (this.btnList != 'undefined' && this.btnList && this.btnList.length > 0) {
					this.btnList.map(v => {
						if (v.code == '右侧') {
							rightBtnArr.push(v);
						}
						if (v.code == '顶部') {
							topBtnArr.push(v);
						}
					});
				}
				this.listTitle = this.currentRouterTitle;
				this.navigationList.push(this.currentRouterItem);
				this.rightToolBar = rightBtnArr;
				this.topToolBar = topBtnArr;
				// if (this.$route.meta.urlId) this.routerId = this.$route.meta.urlId;
				if (this.$route.query.isBack) this.isBack = this.$route.query.isBack;
				this.getListData();
			},
			destroyed() {
				// console.log('组件销毁');
			},
			components: {
				FuzzyQuery: () => import('@/components/searchBar/fuzzyQuery.vue'),
				ConditionQuery: () => import('@/components/searchBar/conditionQuery.vue'),
				navigationBar: () => import('@/components/navigationBar.vue'),
				FromDialog: () => (isCustomForm ? import(`@/components/customForm/${glistName.gname}`) : import('@/components/elementFormBase.vue'))
			},
			template: tpl
		});
	}
}
</script>

<style lang="scss" scoped>
	.listImgPreview {
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		overflow: auto;
		img {
			width: 100%;
		}
	}
	.pages {
		.block {
			margin-top: 10px;
		}
	}
	.nodebook-enter,
	.nodebook-leave-to {
		transform: scale(0.9);
		opacity: 0;
	}

	.nodebook-enter-to,
	.nodebook-leave {
		transform: scale(1);
		opacity: 1;
	}
	.nodebook-enter-active,
	.nodebook-leave-active {
		transition: all 0.3s;
	}
	.list-multiple-img{
		background-color: #fff;
		@include flex-style(row,wrap,flex-start,center);
	}
</style>
