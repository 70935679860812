import {
	formatDate,
	formatStrToDate,
	dateValid
} from './formatdate.js'
import {
	dynamicLoading
} from "./dynamicLoading.js"
import Vue from 'vue'
import axios from 'axios'
import _axios from '../../plugins/axios.js'
import {mapState} from 'vuex'
var myMixin = {
	data() {
		return {
			listTitle:"数据",//列表title
			navigationList:[],//列表title
			routerId:"",//路由id
			isBack:false,//配置是否显示返回按钮
			showToolBar:true,//配置是否显示顶部工具栏
			showSearchBar:true,//配置是否显示搜索框
			showLeftSelect:false,//是否显示左边多选框
			loading:false,//数据加载提醒
			// isExpand:false,//是否开启列表展开行功能
			expandComponentName:"",//自定义展开列组件名
			queryOptions:{
				queryType:"conditionQuery",//配置搜索框类型fuzzyQuery模糊查询，conditionQuery条件查询
				apiUrl:"",//接口地址
				keys:"userName",
				placeHolder:"",//placeholder显示内容
				conditionOption:[
					{label:"输入框",type:"input",prop:"name",tips:"请选择",span:4,},
					{label:"下拉选",type:"dropDownList",prop:"state",tips:"请选择",span:4,option:[{label:"名字",value:"1"},{label:"名字",value:"2"},{label:"名字",value:"3"}]},
					{label:"级联选择",type:"cascader",prop:"user",span:4,
						option:[{
						  value: 'zhinan',
						  label: '指南',
						  children: [{
							value: 'shejiyuanze',
							label: '设计原则',
							children: [{
							  value: 'yizhi',
							  label: '一致'
							}, {
							  value: 'fankui',
							  label: '反馈'
							}, {
							  value: 'xiaolv',
							  label: '效率'
							}, {
							  value: 'kekong',
							  label: '可控'
							}]
						  }, {
							value: 'daohang',
							label: '导航',
							children: [{
							  value: 'cexiangdaohang',
							  label: '侧向导航'
							}, {
							  value: 'dingbudaohang',
							  label: '顶部导航'
							}]
						  }]
						}],
					},
					{label:"单时间",type:"date",prop:"time",span:4,option:[{label:"名字",value:"值"},{label:"名字",value:"值"},{label:"名字",value:"值"}]},
					// {label:"时间桥",type:"daterange",span:6,option:[{label:"名字",value:"值"},{label:"名字",value:"值"},{label:"名字",value:"值"}]},
					// {label:"时间桥",type:"daterange",span:6,option:[{label:"名字",value:"值"},{label:"名字",value:"值"},{label:"名字",value:"值"}]},
					// {label:"时间桥",type:"daterange",span:6,option:[{label:"名字",value:"值"},{label:"名字",value:"值"},{label:"名字",value:"值"}]},
				]
			},//搜索列表配置参数
			formDialogConfig:{//弹窗相关配置参数
				defauleWidth:"50%",//dialog默认宽度
				customWidth:"50%",//dialog自定义宽度
				title:"",//dialog标题
				viewDialogFormVisible:false,//控制查看dialog的显隐
				newDialogFormVisible:false,//控制新建dialog的显隐
				editDialogFormVisible:false,//控制编辑dialog的显隐
				customDialogVisible:false,//控制自定义dialog的显隐
				listImgDialogVisible:false//控制列表图片预览dialog的显隐
			},
			listAxiosConfig:{//列表数据请求接口参数配置
				url:"",//地址
				methods:"POST",//方法
				data:{},//参数
				token:true//token
			},
			listDelConfig:{//列表数据删除参数配置
				url:"",
				methods:"GET",//方法
				keyFiled:"id",//参数名
				valFiled:"",//取值参数名
				token:true//token
			},
			formConfig:{},//表单配置参数
			selectRowData:[],//选中的数据行
			currentSelectRowData: {},//列表当前选中的行数据
			customComponentName:"testCustom",//配置自定义dialog需要展示的自定义组件的名称
			listImgurl:"",//列表上需要展示的图片地址
			tableColumn:[//表头配置
				// {
				// 	prop:"date",
				// 	label:"日期",
				// 	type:"date",//配置内容显示格式
				// 	format:"yyyy-MM-dd",//日期类型配置显示格式
				// 	btntype:"",//按钮类型配置按钮样式
				// 	event:"test",//按钮类型配置点击事件
				// 	option:[{label:"否",value:0},{label:"是",value:1}],//类型为state配置用于值和label切换
				// 	isClick:true,//配置该列是否可点击
				// 	width:"180",
				// 	align:"left"
				// },
			],
			tableData:[],//表格数据
			tableConfig:{//表格参数配置
				tableHeight:"660",//表格高度,可用于固定表头
			},
			paginationConfig:{//分页相关配置
				dataTotal:0,//分页总页数
				pageSize:20,//单页数据条数
				currentPage:1,//当前页
				page:1,//页数
				paging: [10, 20, 30, 40]//配置可选页面显示数据条数数组
			},
			topToolBar: [//配置顶部工具栏按钮
				// {
				// 	name: "新建",
				// 	event: "newData",
				// 	size: "small",
				// 	type: "primary",
				// 	isShow: true
				// },
				// {
				// 	name: "编辑",
				// 	event: "editData",
				// 	size: "small",
				// 	type: "primary",
				// 	isShow: true
				// },
				// {
				// 	name: "删除",
				// 	event: "delData",
				// 	size: "small",
				// 	type: "primary",
				// 	isShow: true
				// }
			],
			rightHandleAreaConfig:{//列表右侧跟随操作区域参数配置
				isShow:true,
				fixed:"right",
				label:"操作",
				// width:"200",
				minwidth:"200"
			},
			rightToolBar: [//配置右侧工具栏按钮
				// {
				// 	name: "查看",
				// 	event: "viewData",
				// 	size: "small",
				// 	type: "primary",
				// 	isShow: true
				// },
				// {
				// 	name: "编辑",
				// 	event: "editData",
				// 	size: "small",
				// 	type: "primary",
				// 	isShow: true
				// },
				// {
				// 	name: "删除",
				// 	event: "delData",
				// 	size: "small",
				// 	type: "primary",
				// 	isShow: true
				// }
			],
			conditionParams:{
				
			},
			searchParams:{
				
			}
		}
	},
	created() {
		this.$vue.$on("reset",_=>{
			this.conditionParams = {}
		})
	},
	methods: {
		//返回上一级页面
		backBtn(){
			this.$router.back(-1)
		},
		//顶部工具栏点击事件
		handle(methodsWords) {
			this[methodsWords](1);
		},
		//右侧工具栏点击事件
		rightHandle(methodsWords,data){
			this[methodsWords](2,data);
		},
		//列表中的按钮点击事件
		customBtnEvent(item,event,prop){
			this[event](item,prop);
		},
		getConditionVal(val){
			if('item' in val){
				const {item,object} = val
				this.conditionParams = Object.assign(this.conditionParams,object)
			}else{
				const {prop,e} = val
				const object = new Object();
				object[prop] = e
				this.conditionParams = Object.assign(this.conditionParams,object)
			}
		},
		//搜索列表方法
		searchList(val){
			this.searchParams = Object.assign(this.conditionParams,val)
			for(let key in this.searchParams){
				if((this.searchParams[key]+'')===""){
					delete this.searchParams[key]
				}
			}
			this.refreshList();
		},
		reset(){
			this.searchParams = {}
			this.refreshList();
		},
		refreshList(){
			//调用数据刷新this.getData();
			this.paginationConfig.page = 1
			this.getListData()
		},
		//关闭dialog
		handleChangeClose(val){
			this.formDialogConfig.viewDialogFormVisible = val
			this.formDialogConfig.newDialogFormVisible = val
			this.formDialogConfig.editDialogFormVisible = val
			this.formDialogConfig.customDialogVisible = val
			this.getListData()
		},
		//dialog确认
		handleChangeConfirm(val){
			this.formDialogConfig.viewDialogFormVisible = val
			this.formDialogConfig.newDialogFormVisible = val
			this.formDialogConfig.editDialogFormVisible = val
			this.formDialogConfig.customDialogVisible = val
			//调用数据刷新this.getData();
			// this.paginationConfig.page = 1
			this.getListData()
		},
		//获取选中行数据
		getRowDatas(selection){
			this.selectRowData = selection
		},
		//获取列表数据
		async getListData(){
			if(this.testData) return;
			this.loading = true
			let pageConfig = {
				pageNum:this.paginationConfig.page,
				pageSize:this.paginationConfig.pageSize
			}
			let param = { ...pageConfig,...this.listAxiosConfig.data,...this.searchParams }
			const res = await this.$axios(this.listAxiosConfig.methods,this.listAxiosConfig.url,param,this.listAxiosConfig.token)
			this.loading = false
			this.tableData = res.records
			this.paginationConfig.dataTotal = parseInt(res.total)
		},
		// rowClick(e){
		// 	this.currentSelectRowData = e
		// 	this.formConfig = {
		// 		formName:this.$route.params.id
		// 	}
		// 	this.formDialogConfig.title = this.listTitle + "-编辑"
		// 	this.formDialogConfig.editDialogFormVisible = true
		// },
		//子列表
		subList(type,data){
			this.$router.push("/SubList/SubList?custom=1&isBack=true")
		},
		//查看
		async viewData(type,data){
			if(this.beforeView){//是否需要在查看之前做一些额外处理
				const res =await this.beforeView(type,data)
				this.currentSelectRowData = res
			}else{
				this.currentSelectRowData = data
			}
			this.formDialogConfig.viewDialogFormVisible = true
			this.formDialogConfig.title = this.listTitle + "-查看"
			this.formConfig = {
				formName:this.$route.params.id
			}
		},
		//新建
		newData(type) {
			this.formDialogConfig.newDialogFormVisible = true
			this.formDialogConfig.title = this.listTitle + "-新建"
			this.formConfig = {
				formName:this.$route.params.id
			}
		},
		//编辑
		async editData(type,data) {
			if(this.beforeEdit){//是否需要在编辑之前做一些额外处理
				const res =await this.beforeEdit(type,data)
				this.currentSelectRowData = res
			}else{
				if(type==2){//type 是否有左边列表多选框的操作2有 
					this.currentSelectRowData = data
				}else{
					if (this.selectRowData.length < 1) {
						alert("您未选择任何数据，请选择一条数据进行编辑！");
						return;
					}
					if (this.selectRowData.length > 1) {
						alert("您选择数据多于一条，请选择一条数据进行编辑！");
						return;
					}
					this.currentSelectRowData = this.selectRowData[0] 
				}
			}
			this.formConfig = {
				formName:this.$route.params.id
			}
			this.formDialogConfig.title = this.listTitle + "-编辑"
			this.formDialogConfig.editDialogFormVisible = true
		},
		//删除
		delData(type,data) {
			if(type==2){
				this.selectRowData = data
			}else{
				if (this.selectRowData.length < 1) {
					alert("您未选择任何数据，请选择一条数据进行编辑！");
					return;
				}
				if (this.selectRowData.length > 1) {
					alert("您选择数据多于一条，请选择一条数据进行编辑！");
					return;
				}
			}
			// var thiz = this
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.handleDelData(this.selectRowData)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				})
			})
		},
		async handleDelData(data){
			// let ids = "";
			// for (let i = 0; i < this.selectRowData.length; i++) {
			// 	let id = this.selectRowData[i][this.KeyField];
			// 	ids = ids==""?id:ids+','+id;
			// }
			console.log(data);
			let url = ""
			let params = {}
			if(this.listDelConfig.type=='arr'){
				url = this.listDelConfig.url
				params = [data[this.listDelConfig.valFiled?this.listDelConfig.valFiled:this.listDelConfig.keyFiled]]
			}else{
				url = `${this.listDelConfig.url}?${this.listDelConfig.keyFiled}=${data[this.listDelConfig.valFiled?this.listDelConfig.valFiled:this.listDelConfig.keyFiled]}`
			}
			const res = await this.$axios(this.listDelConfig.methods,url,params,this.listAxiosConfig.token)
			this.$message({
				type: 'success',
				message: '删除成功!'
			});
			this.getListData();
		},
		//修改pagesize
		handleSizeChange(val){
			this.paginationConfig.pageSize = val
			this.getListData();
		},
		//修改当前page
		handleCurrentChange(val){
			this.paginationConfig.currentPage = val;
			this.paginationConfig.page = val
			this.getListData();
		},
		//列表图片预览
		previewImg(url){
			this.listImgurl = url
			this.formDialogConfig.listImgDialogVisible = true
		},
		tagsClick(){},
		tagsClose(){}
	}
}

// <el-table-column v-else-if="columnItem.type=='date'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
// 	<template slot-scope="scope">
// 		<span>{{scope.row[columnItem.prop].substring(0,str.length-3)}}</span>
// 	</template>
// </el-table-column>
// <el-table-column v-else-if="columnItem.type=='datetime'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
// </el-table-column>
// <div class="listtitle">
// 				<div class="top-navigation">
// 					<el-button type="primary" size="small" plain :icon="item.icon" v-for="(item,index) in navigationList" v-bind:key="index">
// 						<router-link :to="item.uri">{{item.name}}</router-link>
// 					</el-button>
// 				</div>
// 			</div> v-if="isBack"   v-if="queryOptions.queryType=='fuzzyQuery'"
// <navigationBar :navigationList="navigationList"></navigationBar>
function getTemplate(tpl) {
	var tmp =
		`<div class="pages" id="generalList">
			<div class="conditionQuery-bar" v-if="showSearchBar && queryOptions.queryType=='conditionQuery'">
				<ConditionQuery @getSearchVal="getConditionVal" :queryOptions="queryOptions"></ConditionQuery>
			</div>
			<div class="search-new-back-bar">
				<el-page-header v-if="isBack" @back="backBtn"></el-page-header>
				<FuzzyQuery v-if="showSearchBar" @search="searchList" @reset='reset' :queryOptions="queryOptions"></FuzzyQuery>
				<div class="toolBar" v-if="showToolBar">	
					<el-button v-for="(item,indexTool) in topToolBar" size="small" :key="indexTool"
						:type="JSON.parse(item.icon).type"
						:icon="JSON.parse(item.icon)['icon'] || ''" 
						:circle="JSON.parse(item.icon)['circle'] || false" 
						:round="JSON.parse(item.icon)['round'] || false" 
						:plain="JSON.parse(item.icon)['plain'] || false" 
						@click="rightHandle(item.uri)">
						{{JSON.parse(item.icon)['circle'] ? '':item.name}}
					</el-button>
				</div>
			</div>
			<el-dialog :width="formDialogConfig.defauleWidth" :title="formDialogConfig.title" :visible.sync="formDialogConfig.viewDialogFormVisible">
				<div v-if="formDialogConfig.viewDialogFormVisible">
					<FromDialog :formConfig="formConfig" :selectdata="currentSelectRowData" @schange="handleChangeClose" @fchange="handleChangeConfirm" :opentype="3"></FromDialog>
				</div>
			</el-dialog>
			<el-dialog :width="formDialogConfig.defauleWidth" :title="formDialogConfig.title" :visible.sync="formDialogConfig.newDialogFormVisible">
				<div v-if="formDialogConfig.newDialogFormVisible">
					<FromDialog :formConfig="formConfig" :selectdata="currentSelectRowData" @schange="handleChangeClose" @fchange="handleChangeConfirm" :opentype="1"></FromDialog>
				</div>
			</el-dialog>
			<el-dialog :width="formDialogConfig.defauleWidth" :title="formDialogConfig.title" :visible.sync="formDialogConfig.editDialogFormVisible">
				<div v-if="formDialogConfig.editDialogFormVisible">
					<FromDialog :formConfig="formConfig" :selectdata="currentSelectRowData" @schange="handleChangeClose" @fchange="handleChangeConfirm" :opentype="2"></FromDialog>
				</div>
			</el-dialog>
			<el-dialog :width="formDialogConfig.customWidth" :title="formDialogConfig.title" :visible.sync="formDialogConfig.customDialogVisible">
				<div v-if="formDialogConfig.customDialogVisible">
					<component v-bind:is="customComponentName" :selectdata="currentSelectRowData" @schange="handleChangeClose" @fchange="handleChangeConfirm" :opentype="0"></component>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="formDialogConfig.listImgDialogVisible" title="图片预览">
				<div style="width:100%;height:70vh;text-align:center;">
					<img style="max-width: 100%;max-height: 100%;" :src="listImgurl" mode="aspectFit" /> 
				</div>
			</el-dialog>
			<el-table @selection-change="getRowDatas" stripe 
			v-loading="loading" element-loading-text="正在加载中···"
			:height="tableConfig.tableHeight" 
			:data="tableData" 
			border class="table" ref="multipleTable">
				<el-table-column v-if="showLeftSelect" align="center" type="selection" width="55"></el-table-column>
				<template v-for="(columnItem,columnIndex) in tableColumn">
					<el-table-column v-if="columnItem.type=='img'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<el-image
								style="width: 40px; height: 40px;"
								:src="scope.row[columnItem.prop]" 
								:preview-src-list="[scope.row[columnItem.prop]]">
							</el-image>
						</template>
					</el-table-column>
					<el-table-column v-else-if="columnItem.type=='mimg'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<div class="list-multiple-img" v-if="scope.row[columnItem.prop]">
								<el-image 
									v-for="(item,mindex) in scope.row[columnItem.prop].split(',')"
									style="width: 40px; height: 40px;border:1px solid #f5f5f5;margin-right:6px;"
									:src="item" 
									:key="mindex"
									:preview-src-list="scope.row[columnItem.prop].split(',')">
								</el-image>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-else-if="columnItem.type=='button'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<el-button size="mini" :type="columnItem.btntype || 'info'" plain @click="customBtnEvent(scope.row,columnItem.event,columnItem.prop)">{{scope.row[columnItem.prop]}}</el-button>
						</template>
					</el-table-column>
					<el-table-column v-else-if="columnItem.type=='state'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<template v-for="(item,index) in columnItem.option">
								<span v-if="scope.row[columnItem.prop]==item.value" v-bind:key="index">{{item.label}}</span>
							</template>
						</template>
					</el-table-column>
					<el-table-column v-else-if="columnItem.type=='expand'" type="expand" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<component v-bind:is="expandComponentName" :selectdata="scope.row"></component>
						</template>
					</el-table-column>
					<el-table-column v-else-if="columnItem.type=='tags'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<el-tag
							  style="margin-right:10px;margin-top:2px;margin-bottom:2px"
							  v-for="(tag,tindex) in scope.row[columnItem.prop]"
							  :key="tindex"
							  :closable="columnItem.closable?columnItem.closable:false"
							  :type="columnItem.style"
							  @click="tagsClick(tag,scope.row)"
							  @close="tagsClose(tag,scope.row)">
							  {{tag[columnItem.key]}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column v-else-if="columnItem.type=='tagstr'" :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
						<template slot-scope="scope">
							<el-tag
							  style="margin-right:10px;margin-bottom:2px;margin-top:2px"
							  v-for="(tag,tindex) in scope.row[columnItem.prop] && scope.row[columnItem.prop].length>0?scope.row[columnItem.prop].split(','):[]"
							  :key="tindex"
							  :closable="columnItem.closable?columnItem.closable:false"
							  :type="columnItem.style"
							  @click="tagsClick(tag,scope.row)"
							  @close="tagsClose(tag,scope.row)">
							  {{tag}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column v-else :prop="columnItem.prop" :label="columnItem.label" :align="columnItem.align || 'left'" :width="columnItem.width || 200" v-bind:key="columnIndex">
					</el-table-column>
				</template>
				<el-table-column
					v-if="rightHandleAreaConfig.isShow && rightToolBar.length>0"
					align="center"
				    :fixed="rightHandleAreaConfig.fixed"
				    :label="rightHandleAreaConfig.label"
				    :width="rightHandleAreaConfig.width"
					:min-width="rightHandleAreaConfig.minwidth">
				    <template slot-scope="scope">
						<el-button v-for="(item,indexTool) in rightToolBar" size="small" :key="indexTool"
							:type="JSON.parse(item.icon).type" 
							:disabled="item.disabled"
							:icon="JSON.parse(item.icon)['icon'] || ''" 
							:circle="JSON.parse(item.icon)['circle'] || false" 
							:round="JSON.parse(item.icon)['round'] || false" 
							:plain="JSON.parse(item.icon)['plain'] || false" 
							@click="rightHandle(item.uri,scope.row)">
							{{JSON.parse(item.icon)['circle'] ? '':item.name}}
						</el-button>
				    </template>
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="paginationConfig.currentPage"
					:page-sizes="paginationConfig.paging"
					:page-size="paginationConfig.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="paginationConfig.dataTotal"
				></el-pagination>
			</div>	
		</div>`
	return tmp
}



export {
	myMixin,
	getTemplate
}
