module.exports = {
	tableColumnConfig: [{
			prop: "orderNumber",
			label: "订购流水号",
			type: "text", //配置内容显示格式
		},
		{
			prop: "prodName",
			label: "产品名称",
		},
		{
			prop: "isPayed",
			label: "是否已经支付",
			type: "state",
			width:120,
			option:[{label:"已经支付",value:1},{label:"未支付",value:0}]
		},
		{
			prop: "payType",
			label: "支付方式",
			type: "state",
			width:120,
			option:[{label:"微信支付",value:1},{label:"余额支付",value:3}]
		},
		{
			prop: "status",
			label: "订单状态",
			type: "state",
			width:120,
			option:[{label:"待付款",value:1},{label:"待发货",value:2},{label:"待收货",value:3},{label:"待评价",value:4},{label:"成功",value:5},{label:"失效",value:6}]
		},
		{
			prop: "productNums",
			label: "订单商品总数",
			width:120
		},
		{
			prop: "total",
			label: "订单金额",
			width:120
		},
		{
			prop: "actualTotal",
			label: "订单实付金额",
			type: "number",
			width:120
		},
		{
			prop: "reduceAmount",
			label: "优惠总额",
			width:120
		},
		// {
		// 	prop: "orderType",
		// 	label: "订单类型",
		// 	type: "number",
		// },
		// {
		// 	prop: "addrOrderId",
		// 	label: "用户订单地址Id",
		// 	type: "number",
		// },
		// {
		// 	prop: "remarks",
		// 	label: "订单备注",
		// 	type: "",
		// },
		// {
		// 	prop: "ph",
		// 	label: "收货人手机号",
		// 	type: "",
		// },
		// {
		// 	prop: "dvyFlowId",
		// 	label: "物流单号",
		// 	type: "",
		// },
		// {
		// 	prop: "dvyTime",
		// 	label: "发货时间",
		// 	type: "",
		// },
		// {
		// 	prop: "dvyType",
		// 	label: "配送类型",
		// 	type: "",
		// },
		// {
		// 	prop: "finallyTime",
		// 	label: "	完成时间",
		// 	type: "",
		// },
		// {
		// 	prop: "freightAmount",
		// 	label: "	订单运费",
		// 	type: "",
		// 	width:120,
		// },
		// {
		// 	prop: "prodId",
		// 	label: "商品id",
		// 	type: "",
		// },
		{
			prop: "refundSts",
			label: "退款状态",
			type: "state",
			width:120,
			option:[{label:"未处理",value:0},{label:"在处理",value:1},{label:"处理完成",value:2},{label:"拒绝",value:3},{label:"售后过期",value:4}]
		},
		// {
		// 	prop: "updateTime",
		// 	label: "订单更新时间",
		// 	type: "",
		// },
		// {
		// 	prop: "userId",
		// 	label: "订购用户ID",
		// 	type: "",
		// },
		// {
		// 	prop: "cancelTime",
		// 	label: "订单取消时间",
		// 	type: "text",
		// },
		{
			prop: "payTime",
			label: "付款时间",
			type: "",
		},
		{
			prop: "createTime",
			label: "订购时间",
			type: "",
		},
		// {
		// 	prop: "closeType",
		// 	label: "订单关闭原因",
		// 	type: "",
		// },
	],
	formLabelConfig: [
		{
				prop: "orderNumber",
				label: "订购流水号",
				type: "text", //配置内容显示格式
			},
			{
				prop: "prodName",
				label: "产品名称",
			},
			{
				prop: "productNums",
				label: "订单商品总数",
			},
			{
				prop: "total",
				label: "订单金额",
			},
			{
				prop: "actualTotal",
				label: "订单实付金额",
				type: "number",
			},
			{
				prop: "reduceAmount",
				label: "优惠总额",
			},
			// {
			// 	prop: "orderType",
			// 	label: "订单类型",
			// 	type: "number",
			// },
			// {
			// 	prop: "addrOrderId",
			// 	label: "用户订单地址Id",
			// 	type: "number",
			// },
			{
				prop: "createTime",
				label: "订购时间",
				type: "",
			},
			{
				prop: "isPayed",
				label: "是否已经支付",
				type: "state",
				option:[{label:"已经支付",value:1},{label:"未支付",value:0}]
			},
			{
				prop: "payTime",
				label: "付款时间",
				type: "",
			},
			{
				prop: "payType",
				label: "支付方式",
				type: "state",
				option:[{label:"微信支付",value:1},{label:"余额支付",value:3}]
			},
			{
				prop: "remarks",
				label: "订单备注",
				type: "",
			},
			{
				prop: "status",
				label: "订单状态",
				type: "state",
				option:[{label:"待付款",value:1},{label:"待发货",value:2},{label:"待收货",value:3},{label:"待评价",value:4},{label:"成功",value:5},{label:"失败",value:6}]
			},
			{
				prop: "ph",
				label: "收货人手机号",
				type: "",
			},
			{
				prop: "dvyFlowId",
				label: "物流单号",
				type: "",
			},
			{
				prop: "dvyTime",
				label: "发货时间",
				type: "",
			},
			// {
			// 	prop: "dvyType",
			// 	label: "配送类型",
			// 	type: "",
			// },
			{
				prop: "finallyTime",
				label: "	完成时间",
				type: "",
			},
			{
				prop: "freightAmount",
				label: "	订单运费",
				type: "",
			},
			// {
			// 	prop: "prodId",
			// 	label: "商品id",
			// 	type: "",
			// },
			{
				prop: "refundSts",
				label: "退款状态",
				type: "state",
				option:[{label:"未处理",value:0},{label:"在处理",value:1},{label:"处理完成",value:2},{label:"拒绝",value:3},{label:"售后过期",value:4}]
			},
			{
				prop: "updateTime",
				label: "订单更新时间",
				type: "",
			},
			{
				prop: "userId",
				label: "订购用户ID",
				type: "",
			},
			{
				prop: "cancelTime",
				label: "订单取消时间",
				type: "text",
			},
			{
				prop: "closeType",
				label: "订单关闭原因",
				type: "",
			},
	],
	formDataProp:{
	}
}
