const { tableColumnConfig} = require('../config/orderManager/orderList.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/order/page"
		//搜索配置
		this.queryOptions.queryType="conditionQuery"
		this.queryOptions.keys = "orderNumber"
		this.queryOptions.placeHolder ="请输入订单号"
		this.queryOptions.conditionOption = [//1:待付款 2:待发货 3:待收货 4:待评价 5:成功 6:失败
			{label:"支付状态",type:"dropDownList",prop:"isPayed",tips:"请选择",span:8,md:6,option:[{label:"已支付",value:1},{label:"未支付",value:0}]},
			{label:"订单状态",type:"dropDownList",prop:"status",tips:"请选择",span:8,md:6,option:[{label:"待付款",value:1},{label:"待发货",value:2},{label:"待收货",value:3},{label:"待评价",value:4},{label:"成功",value:5},{label:"失败",value:6}]},
			{label:"订单创建时间",type:"daterange",prop:"createTime",start:"startTime",end:"endTime",span:8,md:6,labelwidth:"150px"},
		]
	},
	methods: {
		// test(item,prop){//测试列表上按钮携带的点击事件
		// 	console.log(item)
		// 	console.log(prop)
		// },
		sendGoods(type,item){
			this.formDialogConfig.customWidth ="30%"
			this.currentSelectRowData = item
			this.formDialogConfig.customDialogVisible = true
			this.formDialogConfig.title = "发货管理"
			this.customComponentName = "SendGoods"
		},
		viewData(type,data){
			this.formDialogConfig.customWidth ="90%"
			this.currentSelectRowData = data
			this.formDialogConfig.customDialogVisible = true
			this.formDialogConfig.title = "订单详情-查看"
			this.customComponentName = "OrderDetail"
		}
	},
	components: {
		"SendGoods": () => import("@/components/businessComponent/sendGoods.vue"),
		"OrderDetail": () => import("@/components/businessComponent/orderDetail.vue"),
	},
}