const { tableColumnConfig} = require('../config/store/ProductStandard.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
			// testData:true,
			// tableData:[
			// 	{propId:"1",propName:"ceshi",prodPropValues:[],rule:1}
			// ]
		}
	},
	created(){
		// 产品分类
		// 年代茶（70 80 90） 大师茶   北大1898（燕园情）
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/prodProp/page"
		this.listDelConfig.url= "/sys/prodProp/delete"
		this.listDelConfig.valFiled = "propId"
	},
	customFormDialog:true,
}