const { tableColumnConfig} = require('../config/system/role.js');
module.exports = {
	data(){
		return{
			tableColumn:tableColumnConfig,//表格表头配置
		}
	},
	created(){
		console.log(123123)
		//列表数据请求接口参数配置
		this.listAxiosConfig.url="/sys/sysRole/list"
		this.rightHandleAreaConfig.minwidth="300"
	},
	mounted(){
	},
	methods: {
		//自定义弹窗
		distributeRight(type,item){
			this.currentSelectRowData = item
			this.formDialogConfig.customDialogVisible = true
			this.formDialogConfig.title =item.name + "-权限分配"
			this.customComponentName = "distributeRight"
		}
	},
	components: {
		"distributeRight": () => import("@/components/customForm/distributeRight.vue")
	},
}