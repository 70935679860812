module.exports = {
	tableColumnConfig: [
		{
			prop: "refundId",
			label: "申请记录号",
			type: "text", //配置内容显示格式
		},
		{
			prop: "orderNumber",
			label: "订单流水号",
			type: "text",
		},
		{
			prop: "applyTime",
			label: "申请时间",
			type: "text", //配置内容显示格式
		},
		{
			prop: "refundSts",
			label: "退款状态",
			type: "state",
			option:[{label:"待审核 ",value:1},{label:"同意",value:2},{label:"不同意",value:3}]
		},
		{
			prop: "applyType",
			label: "申请类型",
			type: "state",
			option:[{label:"仅退款",value:1},{label:"退款退货",value:2}]
		},
		{
			prop: "returnMoneySts",
			label: "处理状态",
			type: "state",
			option:[{label:"退款处理中 ",value:0},{label:"退款成功",value:1},{label:"退款失败",value:-1}]
		},
		{
			prop: "buyerMsg",
			label: "申请原因",
			type: "text",
		},
		{
			prop: "goodsNum",
			label: "退货数量",
			type: "text",
		},
		{
			prop: "refundAmount",
			label: "退款金额",
			type: "text",
		},
		{
			prop: "refundSn",
			label: "退款编号",
			type: "text",
		},
		{
			prop: "refundTime",
			label: "退款时间",
			type: "text",
		},
		{
			prop: "flowTradeNo",
			label: "订单支付流水号",
			type: "text",
		},
        {
			prop: "orderAmount",
			label: "订单总金额",
			type: "text",
		},
		{
			prop: "payTypeName",
			label: "订单支付名称",
			type: "text",
		},
		{
			prop: "payType",
			label: "订单支付方式",
			type: "state",
			option:[{label:"微信支付 ",value:1},{label:"支付宝",value:2},{label:"余额",value:3}]
		},
       
		// {
		// 	prop: "outRefundNo",
		// 	label: "第三方退款单号",
		// 	type: "text",
		// },
		{
			prop: "expressName",
			label: "物流公司名称",
			type: "text",
		},
		{
			prop: "expressNo",
			label: "物流单号",
			type: "text",
		},
		// {
		// 	prop: "handelTime",
		// 	label: "卖家处理时间",
		// 	type: "text",
		// },
		
		// {
		// 	prop: "orderId",
		// 	label: "订单ID",
		// 	type: "text",
		// },
		// {
		// 	prop: "orderItemId",
		// 	label: "订单项ID",
		// 	type: "text",
		// },
		
		
		{
			prop: "receiveMessage",
			label: "收货备注",
			type: "text",
		},
		{
			prop: "receiveTime",
			label: "收货时间",
			type: "text",
		}
		// {
		// 	prop: "sellerMsg",
		// 	label: "卖家备注",
		// 	type: "text",
		// }
	],
	formLabelConfig: [
	],
	formDataProp:{
	}
}
