module.exports = {
	data(){
		return {
			// queryOptions:{ queryName: "@FixSearch", fields: "Name,DisplaySequence" }
			queryType:"singleQuery",
		}
	},
	created(){
		// this.showToolBar = false
		this.defauleWidth = "36%"//定义弹窗宽度
		// this.queryOptions={ queryName: "@FixSearch", fields: "Name,DisplaySequence" }
	},
	// customFormDialog:true//自定义表单
}
