module.exports = {
	tableColumnConfig: [{
            prop: "realName",
            label: "真实姓名",
            type: "text", //配置内容显示格式
            width:80,
        },{
			prop: "backImages",
			label: "身份证反面",
            type: "img",
			align: "center",
		},{
			prop: "frontImages",
			label: "身份证正面",
            type: "img",
			align: "center",
		},{
			prop: "idCard",
			label: "身份证号",
			type: "text", //配置内容显示格式
		},{
			prop: "phone",
			label: "手机号",
			type: "text", //配置内容显示格式
		},{
			prop: "status",
			label: "状态",
            type: "state",
			option:[{label:"通过",value:1},{label:"拒绝",value:-1},{label:"未处理",value:0}]
		}
	],
	formLabelConfig: [
	],
	formDataProp:{
	}
}
