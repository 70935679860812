module.exports = {
	tableColumnConfig: [{
			prop: "username",
			label: "账户",
			width:120,
			type: "text", //配置内容显示格式
		},
		{
			prop: "nickName",
			label: "用户昵称",
			width:130,
		},
		{
			prop: "icon",
			label: "用户头像",
			type: "img",
			align: "center",
			width:120,
		},
		{
			prop: "phone",
			label: "手机号码",
			type: "text",
			align: "center",
			width:130,
		},
		{
			prop: "email",
			label: "邮箱",
			type: "text",
		},
		{
			prop: "roleVo",
			label: "角色",
			type: "text",	
			type:"tags",
			key:"name",
			style:"info",
			width:220,
		},
		{
			prop: "status",
			label: "状态",
			type:"state",
			width:90,
			option:[
				{label:"禁用",value:0},
				{label:"启用",value:1}
			]
		},
		{
			prop: "note",
			label: "备注",
		}
	],
	formLabelConfig: [{
			prop: "username",
			label: "账户"
		},
		{
			prop:"password",
			showType:"password",
			label:"密码"
		},
		{
			prop: "nickName",
			label: "用户昵称",
			allowNull:false
		},
		{
			prop: "phone",
			label: "手机号码",
			testType:"mobile"
		},
		{
			prop: "email",
			label: "邮箱",
			allowNull:false,
			testType:"email"
		},
		{
			prop: "status",
			label: "状态",
			showType:"select",
			option:[{label:"禁用",value:0},{label:"启用",value:1}]
		},
		{
			prop: "icon",
			label: "用户头像",
			allowNull:false,
			showType:"imgUploader",
			limitNum:1
		},
		{
			prop: "note",
			label: "备注",
			allowNull:false,
			showType:"textarea"
		}
	],
	formDataProp:{
		username:"",
		nickName:"",
		icon:"",
		phone:"",
		email:"",
		status:1,
		note:""
	}
}
