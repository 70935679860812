const {
	tableColumnConfig
} = require('../config/userManager/userRealNameAuth.js');
module.exports = {
	data() {
		return {
			tableColumn: tableColumnConfig, //表格表头配置
		}
	},
	created() {
		//列表数据请求接口参数配置
		this.listAxiosConfig.url = "/sys/userRealNameAuth/page"

		// this.listAxiosConfig.methods="POST",
		// this.listAxiosConfig.date={
		// 	pageNum:this.paginationConfig.page,
		// 	pageSize:this.paginationConfig.pageSize
		// }
		this.rightHandleAreaConfig.minwidth = "300"
	},
	mounted() {},
	methods: {
		agreeRequest(item, prop) {
			let status = 1
			this.$confirm('是否通过审核？', '操作提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '通过',
				cancelButtonText: '拒绝'
			})
			.then(async () => {
				const res = await this.$axios('GET',
					`/sys/userRealNameAuth/examine?id=${prop.id}&phone=${prop.phone}&status=${status}&userId=${prop.userId}&name=${prop.realName}`,
					"", true)
				if (res.code == '30001') {
					return this.$message.warning(res.msg);
					// return this.$notify.warning(res.msg);
				}
				this.$message.success('已审核通过！');
				this.refreshList()
			})
			.catch( async action => {
				if(action=='cancel'){
					status = -1
					const res = await this.$axios('GET',`/sys/userRealNameAuth/examine?id=${prop.id}&phone=${prop.phone}&status=${status}&userId=${prop.userId}&name=${prop.realName}`,"",true)
					if(res.code=='30001'){
						return this.$message.warning(res.msg);
					}
					this.$message.success('已拒绝！');
					this.refreshList()
				}
			});
		}
	},
	components: {},
}
