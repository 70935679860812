module.exports = {
	data(){
		return {
			tableData: [{
				date: '2016-05-02',
				name: '王小虎',
				header:"http://localhost:8080/img/q.342e6b1d.png",
				address: '上海市普陀区金沙江路 1518 弄',
				city:"背景"
			  }, {
				date: '2016-05-04',
				header:"/img/img.146655c9.jpg",
				name: '王小虎',
				address: '上海市普陀区金沙江路 1517 弄',
				city:"背景"
			  }, {
				date: '2016-05-01',
				header:"/img/img.146655c9.jpg",
				name: '王小虎',
				address: '上海市普陀区金沙江路 1519 弄',
				city:"背景"
			  }, {
				date: '2016-05-03',
				header:"/img/img.146655c9.jpg",
				name: '王小虎',
				address: '上海市普陀区金沙江路 1516 弄',
				city:"背景"
			  }],
			customComponentName:"testCustom"
		}
	},
	components: {
		testCustom: () => (import('@/components/customForm/testCustom.vue'))//自定义弹窗内容组件的引入
	},
	mounted(){
		this.toolBar.push({
					name: "自定义弹窗内容",
					event: "customDialog",
					size: "small",
					type: "primary",
					isShow: true
				})
		this.rightToolBar.push({//配置下级点击按钮
				name: "子列表",
				event: "subList",
				size: "small",
				type: "primary",
				isShow: true
			})
	},
	methods:{
		handleDelData(data){
			console.log(data)
			
		},
		//新建
		customDialog(type) {
			this.formDialogConfig.customDialogVisible = true
			this.formDialogConfig.title = this.listTitle + "-自定义"
			this.formConfig = {
				formName:this.$route.params.id
			}
		},
	}
}
