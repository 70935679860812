module.exports = {
	tableColumnConfig: [
		{
			prop: "orderNumber",
			label: "订单号",
			width:300
		},
		{
			prop: "amout",
			label: "返利金额",
			width:180,
		},
		{
			prop: "status",
			label: "审核状态",
			type: "state",
			width:180,
			option:[{label:"未处理 ",value:0},{label:"通过",value:1},{label:"拒绝",value:-1}],
		},
		{
			prop: "createTime",
			label: "创建时间",
		},
		{
			prop: "toUserId",
			label: "详情",
			type:"expand",
		},
	]
}
