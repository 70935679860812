module.exports = {
	tableColumnConfig: [
        {
			prop: "code",
			label: "快递缩写",
			type: "text", //配置内容显示格式
		},
        {
			prop: "companyHomeUrl",
			label: "公司主页",
			type: "text", //配置内容显示格式
		},
        {
			prop: "dvyName",
			label: "物流公司名称",
			type: "text", //配置内容显示格式
		},
        // {
		// 	prop: "modifyTime",
		// 	label: "修改时间",
		// 	type: "text", //配置内容显示格式
		// },
        {
			prop: "pic",
			label: "快递公司logo",
			type: "img", //配置内容显示格式
		},
        // {
		// 	prop: "queryUrl",
		// 	label: "物流查询接口",
		// 	type: "text", //配置内容显示格式
		// },
        // {
		// 	prop: "recTime",
		// 	label: "建立时间",
		// 	type: "text", //配置内容显示格式
		// }
	],
	formLabelConfig: [
        {
			prop: "code",
			label: "快递缩写",
            formLabelWidth:50,
		},
        {
			prop: "companyHomeUrl",
			label: "公司主页",
		},
        {
			prop: "dvyName",
			label: "名称",
		},
        {
			prop: "pic",
			label: "logo",
            showType:"imgUploader",
			allowNull:false,
			limitNum:1
		},
	],
	formDataProp:{
	}
}
